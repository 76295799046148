<template>
  <b-overlay
    :show="spinnerShow === true"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <validation-observer ref="simpleRules">
      <div>
        <!-- User Info: Input Fields -->
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <!-- Media -->
              <b-media class="mb-2">
                <template #aside>
                  <b-avatar
                    ref="previewEl"
                    :src="userData.profile_image"
                    :text="'Profile Image'"
                    :variant="`light-info`"
                    size="90px"
                    rounded
                  />
                </template>
                <h4 class="mb-1">
                  Profile Image
                </h4>
                <div class="d-flex flex-wrap">
                  <b-button
                    variant="primary"
                    @click="$refs.refInputEl.click()"
                  >
                    <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      @input="inputImageRenderer"
                    >
                    <span class="d-none d-sm-inline">Select Image</span>
                    <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    class="ml-1"
                    @click="resetImage"
                  >
                    <span class="d-none d-sm-inline">Remove</span>
                    <feather-icon
                      icon="TrashIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                </div>
              </b-media>
            </b-col>

            <!-- Field: Full Name -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Name"
                label-for="full-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    id="full-name"
                    v-model="userData.name"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Email -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="userData.email"
                    :state="errors.length > 0 ? false:null"
                    type="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Gender -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label="Gender"
                label-for="gender"
                label-class="mb-1"
              >
                <b-form-radio-group
                  id="gender"
                  v-model="userData.gender"
                  :options="genderOptions"
                  value="male"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Status -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Status"
                label-for="user-status"
              >
                <v-select
                  v-model="userData.active"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="user-status"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Role -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="User Role"
                label-for="user-role"
              >
                <v-select
                  v-model="selected_role"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roles"
                  :clearable="false"
                  input-id="user-role"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Job Title"
                label-for="user-jobTitle"
              >
                <v-select
                  v-model="selected_positions"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="jobTitles"
                  :clearable="false"
                  input-id="job-title"
                />
              </b-form-group>
            </b-col>

          <!-- Field: Email -->

          </b-row>
        </b-form>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click.prevent="validationForm"
        >
          Save Changes
        </b-button>
        <b-button
          variant="outline-secondary"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          Reset
        </b-button>
      </div>
    </validation-observer>
  </b-overlay>
</template>

<script>
/* eslint-disable no-unused-vars */
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BRow, BMedia, BAvatar, BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormRadioGroup,
    vSelect,
    BMedia,
    BAvatar,
    BOverlay,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      spinnerShow: false,
      data_local: this.userData,
      selected_shifts: this.userData.shifts,
      profile_image: this.userData.profile_image,
      selected_shows: this.userData.shows,
      selected_positions: this.userData.positions,
      selected_role: { label: this.userData.role.role, value: this.userData.role.id },
    }
  },

  computed: {
    jobTitles() {
      return store.state.jobTitleStore.jobTitleLabels
    },

    roles() {
      return store.state.rolesStore.roleLabels
    },

    showsOptions() {
      return store.state.showStore.showLabels
    },
  },

  setup(props) {
    const resolveUserRoleVariant = role => {
      if (role === 'Super User') return 'danger'
      if (role === 'Admin') return 'warning'
      if (role === 'Employee') return 'success'
      if (role === 'Public') return 'info'
      return 'primary'
    }

    const statusOptions = [
      { label: 'Active', value: 1 },
      { label: 'Inactive', value: 2 },
    ]

    const genderOptions = [
      { text: 'Male', value: 'Male' },
      { text: 'Female', value: 'Female' },
      { text: 'Other', value: 'Other' },
    ]

    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.profile_image = base64
    })

    return {
      resolveUserRoleVariant,
      statusOptions,
      genderOptions,
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  created() {
    // this.data_local.selected_positions = this.selected_positions
    // this.data_local.selected_shows = this.selected_shows
    // this.data_local.selected_shifts = this.selected_shifts
  },
  methods: {
    resetImage() {
      this.userData.profile_image = null
    },
    validationForm() {
      this.spinnerShow = true
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.selected_positions !== undefined) {
            this.userData.selectedPositions = this.selected_positions.map(show => show.value)
          }
          if (this.selected_role !== undefined) {
            this.userData.selectedRole = this.selected_role.value
          }

          // eslint-disable-next-line
          store.dispatch('userStore/updateUser', this.userData)
            .then(response => {
              this.spinnerShow = false
              this.$bvToast.toast('The details has been updated', {
                title: 'Information Updated',
                variant: 'success',
                solid: true,
              })
            })
            .catch(error => {
              this.$bvToast.toast(error.message, {
                title: 'Error Occurred',
                variant: 'danger',
                solid: true,
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
